.wrapper_main {
  display: flex;
  align-items: center;
  justify-content: space-around;

  margin: 10rem 6rem;
}

/* left column */

.left_col_main {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 0 4rem;
}

.water_logo_main {
  width: 19rem;
}

.title_wrapper_main {
  padding: 2rem 0;
}

.title_main {
  font-weight: 400;
  letter-spacing: 0.2em;
  font-size: 1.5rem;
  text-transform: uppercase;
}

.p_wrapper_main {
  padding: 0rem 2.5rem;
}

.p_main {
  font-weight: 400;
  line-height: 2;
}

/* right column */
.main_carousel {
  border-radius: 3%;
}

.right_col_main {
  width: 550px;
  height: fit-content;
}


/*media query*/

@media screen and (max-width: 1200px) {
  .wrapper_main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0;
    width: 100%;
  }

  .left_col_main {
    padding: 0;
    margin: 0;
    width: 100%;
  }
  .right_col_main {
    width: 95%;
  }
}
.footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #ffffff;

    background-color: #000000;
    opacity: .7;

    padding: 1rem 5rem;
    
    width: 100%;
    height: auto;

    position: sticky;
    bottom:0;
}



.footer__logos {
    display: flex;
    align-items: center;
}
.aecom__logo, .hr__logo {
    height: 3rem;
}

.footer_triple_links > a {
    color: #ffffff;
}

.footer__svgs {
    display: flex;
    justify-content: space-around;
}
.twitter__svg, .facebook__svg, .linkedin__svg {
    width: 30px;
    color: white;
    background-color: transparent;
}

.footer__contact {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

@media screen and (max-width: 1200px) {
    .footer {
        justify-content: center;
        flex-wrap: wrap;
        margin: 0;
    }
    .footer > * {
        padding: 1rem 2rem;
        /* border-bottom: 1px solid white; */
    }
}

@media  screen and (max-width: 448px) {
    .footer > * {
        padding: 1rem 0rem;
        border-bottom: 1px solid white;
    }
}
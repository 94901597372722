#logo {
  position: absolute;
  left: 15px;
  top: 25px;
  /* padding: 22px; */
  z-index: 2;
}

.site_logo {
  height: 10rem;
  z-index: 2;
}

.rc_nav {
  background-color: transparent;
  display: flex;
  justify-content: space-evenly;
  z-index: 3;
  height: fit-content;
  margin: 0rem 10rem;
}


.nav_link {
  padding: 40px 5px 5px 5px;
  font-size: 1.2rem;
  margin: 0rem 3rem;

  text-transform: uppercase;
  text-decoration: none;
}


.activated {
  background-color: #abd7a0;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;

  color: #ffffff;
  font-weight: 600;
}

.nonactivated {
  color: #000000;
  background: transparent;
}

.activated, .nonactivated {
  height: 100%;
  text-decoration: none;
}


.rc_nav .icon {
  display: none;
}

.rc_content {
  text-align: center; 
  padding-left:14px; 
  margin-top: 100px;  
  color: #8e909b;
}

.mobile_nav {
  display: none;
}

.mobile_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0.5rem 4rem;
}

#hamburger {
  height: 5rem;
}

.nav_menu_wrapper {
  width: 100%;
}

.mobile_menu {
  background: rgb(170, 214, 159, 0.95);
  color: #ffffff;

  padding: 0.5rem 4rem;
  margin: 0rem 1rem;
  border-radius: 10px; 
  list-style-type: none;

  text-transform: uppercase;
  text-decoration: none;

  overflow: scroll;

  z-index: 1;
}

.mobile_menu_item {
  opacity: 1;
  border-bottom: 1px solid gray;
  padding: 1rem 0rem;
}

.mobile_menu_item > * {
  color: #ffffff;
  text-decoration: none;
}

#hamburger__button {
  background: transparent;
  border: none;
}

@media  screen and (max-width: 1788px) {
  .rc_nav {
    justify-content: space-around;
  }
}

@media screen and (max-width: 1200px) {
  #logo {
    display: none;
  }
  .rc_nav {
    display: none;
  }
  .mobile_nav {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }

  .mobile_menu {
    position: absolute;
    width: 90%;
  }
}

.slick-slide img {
  margin: auto;
  width: 100%;
  height: 100%;
  border-radius: 10px;
}

.slick-arrow {
  background-color: black;
}

.slick-arrow:hover {
  background-color: orange;
}

.imgslider {
  
  background-color: transparent;
  padding: none;
  margin: none;
}

.slider_interior {
  border-radius: 10px;
  padding: 0;
  width: 200px;
  margin: none;
}

@media screen and (max-width: 1200px) {
  .slick-slide img {
    margin: 0;
  }
}
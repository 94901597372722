
.subtitle__main {
    padding-top: 1rem;
    font-weight: 400;
    text-align: center;
}

.list_heading_documents {
    font-size: 1.2rem;
    margin-left: -2rem;
    margin-top: 1rem;
}

.list_item_documents {
    text-align: left;
}
.document_page_ul > li > a {
    color: #000000;
}
.document_page_ul > li > a:hover {
    color: #abd7a0;
}

@media screen and (max-width: 550px) {
    .subtitle__main, .title_documents{
        margin: 1rem;
        text-align: center;
    }
}

.sidebar__nav {
    width: fit-content;
    margin-left: -11rem;
    
    transform: rotate(270deg);

    position: fixed;
    top: 50%;
}

.sidebar__list {
    background-color: rgb(170, 214, 159);
    color: #ffffff;
    padding: 2rem;
    border-radius: 10px; 
    
    list-style-type: none;
    text-transform: uppercase;
    text-decoration: none;
    font-size: 1rem;
    font-weight: 600;
    
}


.sidebar__list > span {
    padding-left: 1rem;
    padding-right: 2rem;
}
.sidebar__list > span > * {
    color: #ffffff;
    text-decoration: none;
}

@media screen and (max-width: 1200px) {
    .sidebar__nav {
        display: none;
    }

}
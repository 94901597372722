.wrapper_faq {
    display: flex;
    align-items: center;
    justify-content: center;
}

.left_col_faq {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 0 2rem;
}

.faq_collap_wrap {
    display: flex;
    flex-direction: column;
    text-align: left;
    
}


.collapsible_always {
    color: #ffffff;
    background-color: rgb(29,106,144);
    font-size: 2rem;
    text-transform: uppercase;
    border-radius: 10px;
    padding: 1.5rem 3rem;
    cursor: pointer;
    margin: 1rem 2rem;
}

.Collapsible__trigger {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.Collapsible__trigger.is-open svg {
    transform: rotate(180deg);
}

.collapsible_1_open {
    background-color: rgb(29,106,144, 0.6);
}

.collapsible_1_inner {
    font-size: 1rem;
    color: #ffffff;
    text-transform: none;

    background-color: transparent;
}

.question_outer {
    margin-left: 1rem;
}

.question_text {
    font-size: 1.2rem;
    font-weight: 600;
    list-style-type: none;
}

.answer_text {
    list-style-type: square;
    padding-left: 1rem;
}
.answer_text > a {
    color: #ffffff;
}

.answer_text > a:hover {
    color: #abd7a0;
}
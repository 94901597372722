html, body { 
  background-image: url(./assets/single_wave_180.png), url(./assets/single_wave.png);
  background-position: left top, right bottom;
  background-repeat: no-repeat, no-repeat;
  background-size: 25%, 45%;

  background-color: #FFFFFF;


  /* -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover; */

  /* width: 100%;
  height: 100%;
  margin: 0;
  padding: 0; */

  overflow: hidden;
  overflow-y: auto;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Source Sans Pro', sans-serif;
}

@media screen and (max-width: 1200px) {
  html, body {
      background-size: 60%, 60%;
  }
}

@media screen and (max-width: 600px) {
  html, body {
      background-size: contain, contain;
  }
}

/* Brand info for reference
#ABD7A0 - LIGHT GREEN
#4DBFAB - AQUA
#1D6A8F - BLUE
*/
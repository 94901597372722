.schedule__wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;

  
}

.schedule__upcoming, .schedule__past {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    /* justify-content: center; */
    width: 100%;
    height: 100%;
    padding: 2rem 0;
    text-decoration: underline;
}

.schedule__event__list {
    list-style-type: none;
}

.schedule__event__list > li {
    max-width: 450px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    padding: 0.5rem 0;
}

.schedule__event__list > li > span {
    padding: 0.5rem
}

.schedule_event_info {
    text-align: justify;
}

.schedule_event_title {
    font-weight: bold;
}

.schedule_event_date, .schedule_event_loc {
    font-style: italic;
}





@media screen and (max-width: 1200px) {
    .schedule__upcoming, .schedule__past {
        align-items: center;
    }
}
.p_ce {
    text-align: left;
}

.ce_list {
    margin: 0 1.5rem;
}

.p_ce_bottomparagraph, .ce_section_title {
    margin: 1rem 0;
}

.ce_section_title {
    font-weight: 600;
}

.opening_paragraph {
    color:rgb(29,106,144);
    font-weight: bold;
    font-style: italic;
}

/* bottom */
.ce_bottom {
    display: flex;
    align-items: center;
}

.right_col_ce {
    width: 550px;
    height: 400px;
}

@media screen and (max-width: 1450px) {
    .ce_bottom{
        flex-direction: column;
    }
}

@media screen and (max-width: 1200px) {
    .wrapper_main {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin: 0;
      width: 100%;
    }
    .opening_paragraph {
        background-color: #ffffff;
        border: 2px solid rgb(29,106,144);
        border-radius: 10px;
        padding: 1em;
    }
  
    .p_wrapper_ce {
        width: 95%;
    }
    .left_col_ce {
        width: 100%;
        margin: 2em;
    }
    .right_col_ce {
      padding: 4em;
    }

  }